import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import Avatar from "@mui/material/Avatar";
import api from "../Api/AuthApi";
import "aos/dist/aos.css";
AOS.init();

/**
 * Header component that renders the navigation bar for the application.
 * The navigation is responsive, allowing for mobile interactions
 * with dropdown menus and a hamburger menu.
 *
 * Functionality includes:
 * - Toggle for displaying the navigation links on mobile.
 * - Handling clicks outside the dropdown or navigation to close them.
 * - Ensuring the dropdown does not overflow the viewport.
 */
const AUTH_LOCAL_KEY = process.env.REACT_APP_USER_AUTH_KEY;

function Header() {
  // State to manage the visibility of the mobile navigation
  const [showNav, setShowNav] = useState(false);
  // State to manage the open/close state of the treatment dropdown
  const [isOpen, setIsOpen] = useState(false);
  // State to manage authentication
  const [authState, setAuthState] = useState(() => {
    // Initialize auth state from localStorage on component mount
    const savedAuth = localStorage.getItem(AUTH_LOCAL_KEY);
    return savedAuth
      ? JSON.parse(savedAuth)
      : {
          isAuthenticated: false,
          userName: "",
          panelLink: "",
        };
  });
 
  const [profileMenu, setProfileMenu] = useState(false);
  const [treatmentCards, setTreatmentCards] = useState([]);
  // Refs for navigation and dropdown menus for click detection
  const navRef = useRef(null);
  const navigate = useNavigate();
  const mobileDropdownRef = useRef(null);
  const avatarRef = useRef(null); // Ref for the avatar

  /**
   * Toggles the state of the treatment dropdown.
   */
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
  // Memoized auth check function
  const checkAuthStatus = async () => {
    try {
      const response = await api.get("/auth-status");
      const { isAuthenticated, user } = response.data;

      if (!isAuthenticated && localStorage.getItem(AUTH_LOCAL_KEY)) {
        localStorage.removeItem(AUTH_LOCAL_KEY);
        // Handle logout
        setAuthState({
          isAuthenticated: false,
          userName: "",
          panelLink: "",
        });
        return;
      }

      const newAuthState = {
        isAuthenticated,
        userName: user?.name || "",
        panelLink: user?.panel || "",
      };

      setAuthState(newAuthState);
      localStorage.setItem(AUTH_LOCAL_KEY, JSON.stringify(newAuthState));
    } catch (error) {
      setAuthState({
        isAuthenticated: false,
        userName: "",
        panelLink: "",
      });
      localStorage.removeItem(AUTH_LOCAL_KEY);
    }
  };
  checkAuthStatus();
},[]);

  useEffect(() => {
    const fetchTreatmentCards = async () => {
      try {
        const response = await api.get("/api/treatments");
        setTreatmentCards(response.data.data);
      } catch (error) {
        navigate("/page/error");
      }
    };

    fetchTreatmentCards();
  }, [navigate]);

  const handleAvatarClick = (event) => {
    event.stopPropagation(); // Prevent event bubbling
    setProfileMenu((prev) => !prev); // Toggle the profile menu
  };

  // Effect to handle clicks outside of the header and dropdown
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  /**
   * Handles clicks outside the navigation and dropdown to close them.
   * @param {MouseEvent} event - The mouse event triggered by the click.
   */

  const handleClickOutside = (event) => {
    if (
      mobileDropdownRef.current &&
      !mobileDropdownRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
    if (navRef.current && !navRef.current.contains(event.target)) {
      setShowNav(false);
    }

    if (avatarRef.current && !avatarRef.current.contains(event.target)) {
      setProfileMenu(false);
    }
  };

  // Effect to manage dropdown behavior when it is open
  useEffect(() => {
    if (isOpen) {
      // When dropdown is open, adjust styles to prevent overflow
      if (mobileDropdownRef.current) {
        const dropdownMenu = mobileDropdownRef.current.querySelector(
          ".mobile_dropdown_menu"
        );
        if (dropdownMenu) {
          const dropdownHeight = dropdownMenu.offsetHeight; // Get height of the dropdown
          const windowHeight = window.innerHeight; // Get height of the viewport
          // If dropdown exceeds window height, restrict its height and enable scroll
          if (dropdownHeight > windowHeight) {
            document.body.style.overflow = "hidden";
            dropdownMenu.style.maxHeight = `${windowHeight - 50}px`; // Set max height for dropdown
            dropdownMenu.style.overflowY = "auto";
          }
        }
      }
    } else {
      document.body.style.overflow = "";
    }
    const mobileCurrent = mobileDropdownRef.current;
    return () => {
      document.body.style.overflow = "";

      // Reset dropdown styles
      if (mobileCurrent) {
        const dropdownMenu = mobileCurrent.querySelector(
          ".mobile_dropdown_menu"
        );
        if (dropdownMenu) {
          dropdownMenu.style.maxHeight = "";
          dropdownMenu.style.overflowY = "";
        }
      }
    };
  }, [isOpen]);

  // Effect to manage body overflow based on the mobile navigation state
  useEffect(() => {
    if (showNav) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [showNav]);

  /**
   * Toggles the visibility of the mobile navigation menu.
   */
  const toggleNavbar = () => {
    setShowNav(!showNav);
  };

  /**
   * Hides the mobile navigation and closes the dropdown menu.
   */
  const hideMenu = () => {
    setShowNav(false);
    setIsOpen(false);
    setProfileMenu(false); // Close profile menu on menu hide
  };

  const formatTitleForURL = (title) => {
    return title.replace(/ /g, "-").replace(/&/g, "and").replace(/or/g, "or");
  };

  return (
    <div>
      <div id="header">
        <div className="container">
          <div className="nav_wrapper">
            <div className="nav_left_wrapper">
              <Link to="/">
                <div className="logo_div">
                  <img
                    src={require("..//assets/images/balanced-logo.png")}
                    alt="logo"
                  />
                </div>
              </Link>
              <div
                ref={navRef}
                className={`nav_links ${showNav ? "show" : ""}`}
              >
                <div className="mobile_menu_header  justify-content-between align-items-center w-100">
                  <p>Menu</p>
                  <img
                    src={require("../assets/images/closeIcon.png")}
                    alt="Close Icon"
                    onClick={toggleNavbar}
                  />
                </div>

                <hr className="w-100 mobile_menu_line" />
                <ul className="nav_list">
                  <Link
                    to="/"
                    className="nav_link mobile_nav_link"
                    onClick={hideMenu}
                  >
                    <li>Home</li>
                  </Link>
                  <Link
                    to="/our-story"
                    className="nav_link mobile_nav_link"
                    onClick={hideMenu}
                  >
                    <li>About Us</li>
                  </Link>
                  {!authState.isAuthenticated && (
                    <div className="dropdown desktop_dropdown">
                      <a
                        className="dropdown-toggle nav_link"
                        href="#t"
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={toggleDropdown}
                      >
                        What We Treat
                        <img
                          src={require("..//assets/images/arrowNavDown.png")}
                          className={`dropdownArrow ${isOpen ? "open" : ""}`}
                          alt="Dropdown Arrow"
                        />
                      </a>
                      <div
                        className="dropdown-menu mt-3"
                        aria-labelledby="dropdownMenuLink"
                        data-popper-placement="bottom-start"
                        data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="500"
                      >
                        <div className="dropdown-grid">
                          <div className="dropdown-column">
                            {treatmentCards
                              .slice(0, Math.ceil(treatmentCards.length / 2))
                              .map((treatment) => (
                                <Link
                                  key={treatment.id}
                                  to={`/questionnaire/${formatTitleForURL(
                                    treatment.name
                                  )}/${treatment.id}`}
                                  className="dropdown-item"
                                  onClick={hideMenu}
                                >
                                  <span>{treatment.name}</span>
                                </Link>
                              ))}
                          </div>
                          <div className="dropdown-column">
                            {treatmentCards
                              .slice(Math.ceil(treatmentCards.length / 2))
                              .map((treatment) => (
                                <Link
                                  key={treatment.id}
                                  to={`/questionnaire/${formatTitleForURL(
                                    treatment.name
                                  )}/${treatment.id}`}
                                  className="dropdown-item"
                                  onClick={hideMenu}
                                >
                                  <span>{treatment.name}</span>
                                </Link>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <Link
                    to="/how-it-works"
                    className="nav_link mobile_nav_link"
                    onClick={hideMenu}
                  >
                    <li>How It Works</li>
                  </Link>
                  <Link
                    to="/pricing"
                    className="nav_link mobile_nav_link"
                    onClick={hideMenu}
                  >
                    <li>Pricing</li>
                  </Link>
                  {/* Conditional rendering based on isAuthenticated */}
                  {!authState.isAuthenticated && (
                    <>
                      <Link to="/users/login">
                        <button className="cta login_btn mobile_login_btn">
                          Login
                        </button>
                      </Link>
                      <Link to="/quizzes">
                        <button className="cta quiz_btn mobile_login_btn">
                          Take Your Quiz
                        </button>
                      </Link>
                    </>
                  )}
                </ul>
              </div>
            </div>
            <div className="nav_cta">
              {!authState.isAuthenticated && (
                <>
                  <Link to="/quizzes">
                    <button className="cta getStarted_btn">
                      Take your quiz
                    </button>
                  </Link>
                </>
              )}
              {!authState.isAuthenticated && (
                <div ref={mobileDropdownRef} className="mobile_dropdown">
                  <button
                    className="nav_link mobile-toggle"
                    onClick={toggleDropdown}
                  >
                    Our Treatments
                    <img
                      src={require("..//assets/images/arrowNavDown.png")}
                      className={`dropdownArrow ${isOpen ? "open" : ""}`}
                      alt="Dropdown Arrow"
                    />
                  </button>
                  <div
                    className={`mobile_dropdown_menu mt-3 ${
                      isOpen ? "show" : ""
                    }`}
                  >
                    <div className="dropdown-grid">
                      <div className="dropdown-column">
                        {treatmentCards
                          .slice(0, Math.ceil(treatmentCards.length / 2))
                          .map((treatment) => (
                            <Link
                              key={treatment.id}
                              to={`/questionnaire/${formatTitleForURL(
                                treatment.name
                              )}/${treatment.id}`}
                              className="dropdown-item"
                              onClick={hideMenu}
                            >
                              <span>{treatment.name}</span>
                            </Link>
                          ))}
                      </div>
                      <div className="dropdown-column">
                        {treatmentCards
                          .slice(Math.ceil(treatmentCards.length / 2))
                          .map((treatment) => (
                            <Link
                              key={treatment.id}
                              to={`/questionnaire/${formatTitleForURL(
                                treatment.name
                              )}/${treatment.id}`}
                              className="dropdown-item"
                              onClick={hideMenu}
                            >
                              <span>{treatment.name}</span>
                            </Link>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Desktop Buttons */}
              {authState.isAuthenticated ? (
                <>
                  <div className="avatar_wrapper">
                    <Avatar
                      ref={avatarRef}
                      onClick={handleAvatarClick}
                      sx={{
                        bgcolor: "#249693", // Set background color
                        color: "#fff", // Set text color
                        cursor: "pointer",
                      }}
                    >
                      {authState.userName
                        ? authState.userName.charAt(0).toUpperCase()
                        : ""}
                    </Avatar>

                    {profileMenu && (
                      <div className="avatar-menu bg-white rounded-lg shadow-lg w-64">
                        <div className="avatar-menu-info d-flex align-items-center">
                          <svg
                            class="fi-dropdown-list-item-icon h-5 w-5 text-gray-400 dark:text-gray-500"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                            data-slot="icon"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-5.5-2.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM10 12a5.99 5.99 0 0 0-4.793 2.39A6.483 6.483 0 0 0 10 16.5a6.483 6.483 0 0 0 4.793-2.11A5.99 5.99 0 0 0 10 12Z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                          <span className="avatar-menu-name">
                            {authState.userName}
                          </span>
                        </div>
                        <hr />

                        <a
                          href={authState.panelLink}
                          className="avatar_panel_link_wrapper block hover:bg-gray-50 rounded transition-colors duration-150"
                          onClick={hideMenu}
                          ref={avatarRef}
                        >
                          <div className="avatar_panel_link border-t border-gray-200">
                            My Account
                          </div>
                        </a>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <Link to="/users/login">
                    <button className="cta login_btn">Login</button>
                  </Link>
                </>
              )}
              <div className="mobile_menu" onClick={toggleNavbar}>
                <img
                  src={require("../assets/images/mobileMenu.png")}
                  alt="Hamburger Menu Icon"
                  onClick={toggleNavbar}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
